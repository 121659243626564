import api from '../api';

const getAllUsers = async () => {
    return await api.get(`users`);
};

const remove = async (id) => {
    return await api.delete(`users/${id}`);
};

const get = async (id) => {
    return await api.get(`users/${id}`);
};

const create = async (data) => {
    return await api.put(`users`, data);
};

const profile = async () => {
    return await api.get(`users/profile`);
};

const roles = async () => {
    return await api.get(`users/roles`);
};

const renewApiKey = async (id) => {
    api.put(`users/updateApiKey/${id}`).then((res) => {
        alert(res.data.message);
        return res;
    });
};

const UserService = {
    getAllUsers,
    remove,
    create,
    get,
    profile,
    roles,
    renewApiKey,
};

export default UserService;
