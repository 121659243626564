import React, { useState, useEffect } from 'react';
import AddUserForm from './AddUserForm';

import UserService from '../../services/user.service';
import Modal from '../Modal/Modal';
import DeletingStatus from '../Shared/DeletingStatus';
import { delay } from '../../utils';

function Users() {
    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [users, setUsers] = useState();

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = () => {
        UserService.getAllUsers().then((resp) => {
            resp.data.sort((a, b) => a.id - b.id);
            setUsers(resp.data);
        });
    };

    //Add User Form
    const handleAddUserFormCancel = () => {
        // close AddUserForm
        setShowAddUserForm(false);
    };

    // delete user or update api key
    const [deleting, setDeleting] = useState(false);
    const [deletingText, setDeletingText] = useState(null);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteSuccessText, setDeleteSuccessText] = useState(null);
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorText, setDeleteErrorText] = useState(null);

    const deleteUserById = async (id) => {
        try {
            setDeleting(true);
            setDeletingText(`Lösche User ${id} ...`);
            await delay(1000);
            await UserService.remove(id);
            setDeleteSuccess(true);
            setDeleteSuccessText(`User ${id} erfolgreich gelöscht`);
        } catch (err) {
            setDeleteError(err.message);
            setDeleteErrorText(`User ${id} konnte nicht gelöscht werden.`);
            await delay(1000);
        } finally {
            setDeleting(false);
            getAllUsers();
            await delay(1000);
            setDeleteSuccess(null);
            setDeleteError(null);
        }
    };
    const renewApiKeyById = async (id) => {
        try {
            setDeleting(true);
            setDeletingText(`Erneuere Api Key ${id} ...`);
            await delay(1000);
            await UserService.renewApiKey(id);
            setDeleteSuccess(true);
            setDeleteSuccessText(`Apikey von User ${id} erfolgreich erneuert`);
        } catch (err) {
            setDeleteError(err.message);
            setDeleteErrorText(`Api Key von User ${id} konnte nicht erneuert werden.`);
            await delay(1000);
        } finally {
            setDeleting(false);
            getAllUsers();
            await delay(1000);
            setDeleteSuccess(null);
            setDeleteError(null);
        }
    };

    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [showRenewApiKeyModal, setShowRenewApiKeyModal] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState();
    const [userIdToRenewApiKey, setUserIdToRenewApiKey] = useState();

    const handleDeleteUserClick = (id) => {
        //show modal
        setShowDeleteUserModal(true);
        setUserIdToDelete(id);
    };

    const handleDeleteUser = () => {
        // delete user
        deleteUserById(userIdToDelete);
        // close the modal
        setShowDeleteUserModal(false);
        setUserIdToDelete();
    };

    const handleDeleteUserCancel = () => {
        // close the modal
        setShowDeleteUserModal(false);
        setUserIdToDelete();
    };

    const handleRenewApiKeyClick = (id) => {
        //show modal
        setShowRenewApiKeyModal(true);
        setUserIdToRenewApiKey(id);
    };

    const handleRenewApiKey = () => {
        //renew Api Key
        renewApiKeyById(userIdToRenewApiKey);
        //close the modal
        setShowRenewApiKeyModal(false);
        setUserIdToRenewApiKey();
    };

    const handleRenewApiKeyCancel = () => {
        // close the modal
        setShowRenewApiKeyModal(false);
        setUserIdToRenewApiKey();
    };

    return (
        <main>
            <article>
                <header>
                    <h2>
                        <b>Users</b>
                    </h2>
                </header>
            </article>
            <section className="users">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Roles</th>
                            <th>Date joined</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users &&
                            users.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.roles}</td>
                                    <td>{user.date_joined}</td>
                                    <td>
                                        <section className="input-group row">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    handleDeleteUserClick(user.id);
                                                }}
                                            >
                                                Delete
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    handleRenewApiKeyClick(user.id);
                                                }}
                                            >
                                                Renew Api Key
                                            </button>
                                        </section>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </section>
            <button
                type="button"
                onClick={() => {
                    setShowAddUserForm(true);
                }}
            >
                Add user
            </button>
            <DeletingStatus
                deleting={deleting}
                deletingText={deletingText}
                error={deleteError}
                errorText={deleteErrorText}
                success={deleteSuccess}
                successText={deleteSuccessText}
            />
            <AddUserForm show={showAddUserForm} onCancel={handleAddUserFormCancel} onUpdate={getAllUsers} />
            <Modal
                show={showDeleteUserModal}
                onConfirm={handleDeleteUser}
                onCancel={handleDeleteUserCancel}
                title="Lösche User"
                text={'Möchten Sie den User ' + userIdToDelete + ' wirklich löschen?'}
                confirmButtonLabel="Löschen"
                cancelButtonLabel="Abbrechen"
            />
            <Modal
                show={showRenewApiKeyModal}
                onConfirm={handleRenewApiKey}
                onCancel={handleRenewApiKeyCancel}
                title="Api Key erneuern"
                text={'Möchten Sie den Api Key des Users ' + userIdToRenewApiKey + ' wirklich erneuern?'}
                confirmButtonLabel="Erneuern"
                cancelButtonLabel="Abbrechen"
            />
        </main>
    );
}

export default Users;
