import React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FcOk, FcHighPriority } from 'react-icons/fc';
import { MdLogin } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import FriendlyCaptcha from './FriendlyCaptcha'; // Annahme: Ihre FriendlyCaptcha-Komponente ist in einer separaten Datei
import api from '../../api';
import AuthService from '../../services/auth.service';

import styles from './Login.module.css';

function Login() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const [status, setStatus] = useState(false);
    const [captchaSolution, setCaptchaSolution] = useState(null);

    useEffect(() => {
        getHealtcheck();
    }, []);

    const getHealtcheck = () => {
        api.get('healthcheck')
            .then(() => {
                setStatus(true);
            })
            .catch(() => {
                setStatus(false);
            });
    };

    const postLogin = (data) => {
        console.log('post login:');
        if (captchaSolution) {
            data.captchaSolution = captchaSolution;
            data.sitekey = 'FCMMG09U8I7MO59Q';
        }
        AuthService.login(data).then((resp) => {
            if (resp.status === 200) {
                navigate('/');
            } else {
                setError('root.serverError', { type: 'backend', message: resp.response.data.message });
            }
        });
    };

    const handleSubmitWithCaptcha = async (data) => {
        postLogin(data);
    };

    return (
        <div id="login" className={styles['login-page']}>
            <form onSubmit={handleSubmit(handleSubmitWithCaptcha)} id="loginForm" className={styles['login-form']}>
                <div className="form-row">
                    <div className="form-group col">
                        <header>
                            <h1 className={styles['heading']}>
                                <b>Herzlich Willkommmen beim Meldeformular-Creator</b>
                                <img src={'/images/trillerpfeife.svg'} aria-hidden="true" />
                            </h1>
                            <section className={styles['gradient']}></section>
                        </header>
                        <main>
                            <section className={styles['login-input-group']}>
                                <label htmlFor="email">E-Mail Adresse:</label>
                                <input
                                    id="email"
                                    type="email"
                                    name="email"
                                    {...register('email', { required: true })}
                                />
                            </section>
                            <section className={styles['login-input-group']}>
                                <label htmlFor="password">Passwort:</label>
                                <input
                                    id="password"
                                    type="password"
                                    name="password"
                                    {...register('password', { required: true })}
                                />
                            </section>
                            <div className={styles['login-form-actions']}>
                                <section className={styles['captcha']}>
                                    <FriendlyCaptcha onCaptchaSolved={setCaptchaSolution} />
                                    {/*<div ref={container} className="frc-captcha" data-sitekey="FCMNFOKIM8FS7T6L" />-->*/}
                                </section>
                                <div className={styles['login-form-login']}>
                                    <section className="status_section">
                                        <label>Status {status ? <FcOk /> : <FcHighPriority />}</label>
                                    </section>
                                    <button type="submit" className="button-primary">
                                        <b> Anmelden</b>
                                        <MdLogin />
                                    </button>
                                </div>
                            </div>
                            <section className="error" aria-live="polite">
                                {errors.root && <label>{errors.root.serverError.message}</label>}
                            </section>
                        </main>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login;
