import React from 'react';
import styles from './WidgetPreviewCreateItem.module.css';

const WidgetPreviewCreateItem = ({ onClick }) => {
    return (
        <li className={styles.item}>
            <button onClick={onClick}>
                <b>+</b> neues Meldeformular anlegen
            </button>
        </li>
    );
};

export default WidgetPreviewCreateItem;
