import React from 'react';
import { useState, useEffect } from 'react';

import SnippetService from '../../services/snippet.service';
import LoadingStatus from '../Shared/LoadingStatus';

const SnippetLoader = (props) => {
    const { id } = props;

    // load snippet code as document
    const [snippet, setSnippet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        getSnippetCode(id);
    }, [id]);

    const getSnippetCode = async (id) => {
        try {
            const response = await SnippetService.get(id);
            setSnippet(response.data);
            setError(null);
        } catch (err) {
            setError(err.message);
            setSnippet(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <LoadingStatus
                loading={loading}
                loadingText="Lade Snippet ..."
                error={error}
                errorText="Snippet konnte nicht geladen werden"
            />
            {snippet && <pre>{snippet}</pre>}
        </React.Fragment>
    );
};

export default SnippetLoader;
