import React from 'react';
import { FaCheck, FaRegEdit, FaRegTrashAlt, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styles from './PreviewItem.module.css';

const createLocalString = (d) => {
    if (d) return d.slice(0, 19).replace('T', ' ');
};

const WidgetPreviewItem = ({ widget, onDelete }) => {
    return (
        <li key={widget.id} className={styles.item}>
            <header className="column justify_between">
                <div className={styles.header}>
                    <h4 className={styles.headline}>
                        <b className={styles['id-string']}>{widget.title}</b>
                    </h4>
                    <Link
                        type="button"
                        to={`/editor/${widget.id}`}
                        className={`${styles.button} ${styles['primary-button']}`}
                    >
                        <b className={styles['button-text']}>Bearbeiten</b>
                        <span>
                            <FaRegEdit />
                        </span>
                    </Link>
                </div>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>Domain</td>
                            <td>
                                <b>{widget.referrer_domain}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>ID</td>
                            <td>
                                <b>{widget.id}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>created at</td>
                            <td>
                                <b>{createLocalString(widget.created_at)}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Überschrift des Meldeformulars</td>
                            <td>
                                <b>{widget.text.slice(0, 60)}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Formale Anrede</td>
                            <td>
                                {widget.formal_addressing ? (
                                    <span>
                                        <FaCheck />
                                    </span>
                                ) : (
                                    <span>
                                        <FaTimes />
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Anzahl Meldungen</td>
                            <td>
                                <b>{widget.counter_of_reports}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Headerbild</td>
                            <td>
                                {widget.use_custom_header_image ? (
                                    <span>
                                        <FaCheck />
                                    </span>
                                ) : (
                                    <span>
                                        <FaTimes />
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>Hauptfarbe</td>
                            <td>
                                <b>{widget.color_main}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Akzentfarbe</td>
                            <td>
                                <b>{widget.color_secondary}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Sprache des Meldeformulars</td>
                            <td>
                                <b>Deutsch</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </header>
            <section className={styles.footer}>
                <Link
                    widget={widget}
                    type="button"
                    to={`/snippetWithBanner/widget/${widget.id}`}
                    className={styles.button}
                >
                    <b>&lt;Code mit Bestätigungsfenster &gt;</b>
                </Link>
                <button className={styles.button} onClick={() => onDelete(widget.id)}>
                    <b className={styles['button-text']}>Löschen</b>
                    <span>
                        {' '}
                        <FaRegTrashAlt />
                    </span>
                </button>
            </section>
        </li>
    );
};

export default WidgetPreviewItem;
