import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import WidgetService from '../../services/widget.service';
import AssetsService from '../../services/assets.service';
import { delay } from '../../utils';
import PreviewIframe from './PreviewIframe';
import { FormStyle, FormHeader, FormMeta } from './Forms';
import WidgetFormValidationSchema from './FormValidation';
import LoadingStatus from '../Shared/LoadingStatus';
import SavingStatus from '../Shared/SavingStatus';

import styles from './WidgetEdit.module.css';
import useFormDirtyStateWarning from './hooks/useFormDirtyStateWarning';

const WidgetEdit = () => {
    const { id } = useParams();

    // get persisted widget parameters
    const [widget, setWidget] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(null);
    const getWidgetParameters = async () => {
        try {
            const response = await WidgetService.get(id);
            setWidget(response.data);
            setPreview(response.data);
            // initial iframe dimensions
            setIframeHeight(computeIframeDimensions(response.data)[0]);
            setIframeWidth(computeIframeDimensions(response.data)[1]);
            setLoadingError(null);
        } catch (err) {
            setLoadingError(err.message);
            setWidget(null);
        } finally {
            setLoading(false);
        }
    };

    // get available header images (their ids)
    const [headerImgs, setHeaderImgs] = useState(null);
    const [loadingHI, setLoadingHI] = useState(true);
    const [loadingHIError, setLoadingHIError] = useState(null);
    const getHeaderImgIds = async () => {
        try {
            const response = await AssetsService.getHeaderImages();
            setHeaderImgs(response.data);
            setLoadingHIError(null);
        } catch (err) {
            setLoadingHIError(err.message);
            setHeaderImgs(null);
        } finally {
            setLoadingHI(false);
        }
    };

    useEffect(() => {
        getWidgetParameters();
        getHeaderImgIds();
    }, []);

    // iframe parameters
    const computeIframeDimensions = (params) => {
        const height = params?.use_relative_dimensions ? params?.height_percent + '%' : params?.height_px;
        const width = params?.use_relative_dimensions ? params?.width_percent + '%' : params?.width_px;
        return [height, width];
    };
    const [iframeHeight, setIframeHeight] = useState();
    const [iframeWidth, setIframeWidth] = useState();

    // setup form
    const methods = useForm({
        defaultValues: widget,
        resolver: yupResolver(WidgetFormValidationSchema),
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const {
        reset,
        handleSubmit,
        formState: { isDirty },
        getValues,
    } = methods;

    useEffect(() => {
        reset(widget);
    }, [reset, widget]);

    // save widget, i.e. submit form data to backend
    const [saving, setSaving] = useState(false);
    const [savingText, setSavingText] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveSuccessText, setSaveSuccessText] = useState(null);
    const [saveError, setSaveError] = useState(false);
    const [saveErrorText, setSaveErrorText] = useState(null);
    const putWidgetParameters = async (data) => {
        try {
            setSaving(true);
            setSavingText(`Speichere Widget ...`);
            await delay(1000);
            await WidgetService.edit(id, data);
            setSaveSuccess(true);
            setSaveSuccessText(`Widget gespeichert.`);
        } catch (err) {
            setSaveError(err.code);
            setSaveErrorText(`Widget konnte nicht gespeichert werden.`);
            await delay(1000);
        } finally {
            setSaving(false);
            getWidgetParameters();
            await delay(1000);
            setSaveSuccess(null);
            setSaveError(null);
        }
    };

    const { DirtyStateWarningModal } = useFormDirtyStateWarning(isDirty, async () => {
        const values = getValues();
        await putWidgetParameters(values);
    });

    return (
        <React.Fragment>
            <DirtyStateWarningModal />
            <aside>
                <LoadingStatus
                    loading={loading}
                    loadingText={`Lade Parameter für Meldeformular ${id}`}
                    error={loadingError}
                    errorText={`Parameter für Meldeformular ${id} konnten nicht geladen werden`}
                    className="content-padding"
                />
                <LoadingStatus
                    loading={loadingHI}
                    loadingText="Lade IDs der verfügbaren Headerbilder"
                    error={loadingHIError}
                    errorText="IDs der Headerbilder konnten nicht geladen werden"
                    className="content-padding"
                />
                {widget && (
                    <React.Fragment>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(putWidgetParameters)} className="content-padding">
                                <div className={styles['form-grid']}>
                                    <h3>
                                        <b>Meta</b>
                                    </h3>
                                    <FormMeta />
                                    {/* <h3> */}
                                    {/*     <b>Größenparameter</b> */}
                                    {/* </h3> */}
                                    {/* <FormDimensions */}
                                    {/*     setIframeHeight={setIframeHeight} */}
                                    {/*     setIframeWidth={setIframeWidth} */}
                                    {/*     computeIframeDimensions={computeIframeDimensions} */}
                                    {/* /> */}
                                    <h3>
                                        <b>Gestaltung</b>
                                    </h3>
                                    <FormHeader headerImgs={headerImgs} />
                                    <FormStyle headerImgs={headerImgs} />
                                    {
                                        <div className="button_group">
                                            <button type="button" onClick={() => setPreview(methods.getValues())}>
                                                Vorschau
                                            </button>
                                            <button type="submit">Speichern</button>
                                            <button
                                                type="button"
                                                className="reset"
                                                onClick={() => {
                                                    reset();
                                                    setPreview(methods.getValues());
                                                }}
                                            >
                                                Zurücksetzen
                                            </button>
                                        </div>
                                    }
                                </div>
                            </form>
                        </FormProvider>
                    </React.Fragment>
                )}
                <SavingStatus
                    saving={saving}
                    savingText={savingText}
                    error={saveError}
                    errorText={saveErrorText}
                    success={saveSuccess}
                    successText={saveSuccessText}
                />
            </aside>
            <main id="canvas">
                {preview && <PreviewIframe params={preview} height={iframeHeight} width={iframeWidth} />}
            </main>
        </React.Fragment>
    );
};

export default WidgetEdit;
