import React, { useState, useEffect } from 'react';
import WidgetService from '../../services/widget.service';

function WidgetsOverview() {
    const [widgets, setWidgets] = useState();

    useEffect(() => {
        getAllWidgets();
    }, []);

    const getAllWidgets = () => {
        WidgetService.getAllWidgets().then((resp) => {
            resp.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            setWidgets(resp.data);
        });
    };

    const createLocalString = (d) => {
        if (d) return d.slice(0, 19).replace('T', ' ');
    };

    return (
        <main>
            <article>
                <header>
                    <h2>
                        <b>Widgets</b>
                    </h2>
                </header>
            </article>
            <section className="users">
                <table>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Username</th>
                            <th>Widgetname</th>
                            <th>Domain</th>
                            <th>Counts</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {widgets &&
                            widgets.map((widget) => (
                                <tr key={widget.user_id}>
                                    <td>{widget.user_id}</td>
                                    <td>{widget.username}</td>
                                    <td>{widget.title}</td>
                                    <td>{widget.referrer_domain}</td>
                                    <td>{widget.counter_of_reports}</td>
                                    <td>{createLocalString(widget.created_at)}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </section>
        </main>
    );
}

export default WidgetsOverview;
