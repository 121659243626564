import React, { useContext } from 'react';
import { useState, useEffect } from 'react';

import SnippetService from '../../services/snippet.service';
import LoadingStatus from '../Shared/LoadingStatus';
import { SnippetViewContext } from './SnippetViewWithBanner';

const SnippetLoaderWithBanner = (props) => {
    const { id } = props;

    // load snippet with banner code as document
    const [snippetWithBanner, setSnippetWithBanner] = useState(null);
    const [loadingWithBanner, setLoadingWithBanner] = useState(true);
    const [errorWithBanner, setErrorWithBanner] = useState(null);

    useEffect(() => {
        getSnippetWithBannerCode(id);
    }, [id]);

    const { setSnippetCodeWithBanner } = useContext(SnippetViewContext);

    const getSnippetWithBannerCode = async (id) => {
        try {
            const response = await SnippetService.get_snippet_with_banner(id);
            setSnippetWithBanner(response.data);
            setSnippetCodeWithBanner(response.data);
            setErrorWithBanner(null);
        } catch (err) {
            setErrorWithBanner(err.message);
            setSnippetWithBanner(null);
        } finally {
            setLoadingWithBanner(false);
        }
    };

    return (
        <React.Fragment>
            <LoadingStatus
                loading={loadingWithBanner}
                loadingText="Lade Snippet with banner..."
                error={errorWithBanner}
                errorText="Snippet mit Banner konnte nicht geladen werden"
            />
            {snippetWithBanner && <pre>{snippetWithBanner}</pre>}
        </React.Fragment>
    );
};

export default SnippetLoaderWithBanner;
