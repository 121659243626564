import api from '../api';

const login = async (data) => {
    return await api
        .post('auth/login', data)
        .then((response) => {
            if (response.data.token) {
                sessionStorage.setItem('access_token', response.data.token);
            }
            return response;
        })
        .catch((error) => {
            return error;
        });
};

const logout = () => {
    return api.get('auth/logout').then(() => {
        sessionStorage.removeItem('access_token');
    });
};

const getAccessToken = () => {
    return sessionStorage.getItem('access_token');
};

const AuthService = {
    // register,
    login,
    logout,
    getAccessToken,
};

export default AuthService;
