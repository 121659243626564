const generate = (length) => {
    return Math.random()
        .toString(36)
        .slice(2, length + 2);
};

const passwords = {
    generate,
};

export default passwords;
