import React, { useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import AuthService from '../services/auth.service.js';
import HeaderDropdownMenu from './HeaderDropdownMenu.js';
import { useTranslation } from 'react-i18next';

import styles from './Header.module.css';

const languageOptions = [{ value: 'de', text: 'languageOptions.german' }];

function Header() {
    const navigate = useNavigate();

    const logout = () =>
        AuthService.logout().then(() => {
            navigate('/login');
        });

    const { t, i18n } = useTranslation('header');

    const translatedLanguageOptions = useMemo(
        () => languageOptions.map((option) => ({ ...option, text: t(option.text) })),

        [i18n.language],
    );

    const onLanguageSelect = i18n.changeLanguage;

    return (
        <header lang="de" className={styles['header']}>
            <nav className={styles['nav-list']}>
                <div>
                    <NavLink to="/">Übersicht</NavLink>
                    <NavLink to="/account">Account</NavLink>
                    <NavLink to="/help">Hilfe</NavLink>
                    <NavLink to="/users">Users</NavLink>
                    <NavLink to="/widgets">Formulare</NavLink>
                    {/*<NavLink to="/develop">Develop</NavLink>*/}
                </div>
                <div>
                    <HeaderDropdownMenu
                        id="language-dropdown"
                        options={translatedLanguageOptions}
                        onSelect={onLanguageSelect}
                        value={i18n.language}
                    >
                        {t('language')}
                    </HeaderDropdownMenu>
                    <div>
                        <button className={styles['logout-button']} onClick={logout}>
                            Logout <MdLogout />
                        </button>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
