import React, { useEffect, useMemo, useState, useRef } from 'react';

import styles from './HeaderDropdownMenu.module.css';

/**
 * @param {Object} props
 * @param {Array<{ value: string, label: string, disabled?: boolean }} props.options
 * @param {() => string} props.onSelect
 * @param {string} props.value
 * @param {string} props.id
 * @param {React.ReactNode} props.children
 */
function HeaderDropdownMenu(props) {
    const itemId = (index) => `${props.id}-menuitem-${index}`;
    const menuId = `${props.id}-menu`;

    const containerRef = useRef(null);
    const buttonRef = useRef(null);
    const menuRef = useRef(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const [activeDescendent, setActiveDescendent] = useState(0);

    const menuButtonClick = () => {
        setMenuOpen(!menuOpen);
        setActiveDescendent(0);
    };

    useEffect(() => {
        if (menuOpen) menuRef.current.focus();
    }, [menuOpen]);

    /** @type{Map<string, number>} */
    const optionsMap = useMemo(() =>
        props.options.reduce((prev, next, index) => {
            prev.set(next.value, index);
            return prev;
        }, new Map()),
    );

    const handleMenuKeydown = (e) => {
        const key = e.key;
        if (key === 'Escape') {
            buttonRef.current.focus();
            setMenuOpen(false);
        }

        if (key === 'ArrowDown' || key === 'ArrowUp') {
            e.preventDefault();
            if (!menuOpen) {
                setMenuOpen(true);
                setActiveDescendent(key === 'ArrowDown' ? 0 : props.options.length - 1);
            } else {
                navigateItems(key === 'ArrowDown' ? 1 : -1);
            }
        }

        if (key === 'Home' || key === 'End') {
            e.preventDefault();
            setActiveDescendent(key === 'Home' ? 0 : props.options.length - 1);
        }

        if (key === 'Enter' || key === 'Space') {
            if (!menuOpen) {
                setMenuOpen(true);
                menuRef.current.focus();
            } else if (!props.options[activeDescendent].disabled) {
                props.onSelect(props.options[activeDescendent].value);
            }
        }
    };

    const navigateItems = (direction) => {
        let newDescendant = activeDescendent + direction;

        if (newDescendant < 0) newDescendant = props.options.length - 1;
        if (newDescendant >= props.options.length) newDescendant = 0;

        setActiveDescendent(newDescendant);
    };

    const onBlur = (e) => {
        const relatedTarget = e.relatedTarget;

        if (relatedTarget != null && containerRef.current.contains(relatedTarget)) return;

        setMenuOpen(false);
    };

    return (
        <div
            className={`${styles['menu']} ${menuOpen ? styles['open'] : ''}`}
            onKeyDown={handleMenuKeydown}
            ref={containerRef}
        >
            <button
                id={props.id}
                onClick={menuButtonClick}
                aria-expanded={menuOpen}
                aria-haspopup={true}
                aria-controls={menuId}
                ref={buttonRef}
            >
                {props.children}
            </button>
            <ul
                id={menuId}
                role="menu"
                aria-labelledby={props.id}
                aria-activedescendant={menuOpen ? itemId(activeDescendent) : null}
                ref={menuRef}
                tabIndex={menuOpen ? '0' : '-1'}
                onBlur={onBlur}
            >
                {props.options.map((option, key) => (
                    <li
                        id={itemId(key)}
                        role="menuitemradio"
                        key={key}
                        className={key === activeDescendent ? styles['active'] : ''}
                        aria-checked={optionsMap.get(props.value) === key}
                        aria-disabled={option.disabled}
                        onClick={() => !option.disabled && props.onSelect(option.value)}
                    >
                        {option.text}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default HeaderDropdownMenu;
