import React, { useContext } from 'react';
import { useState, useEffect } from 'react';

import SnippetService from '../../services/snippet.service';
import LoadingStatus from '../Shared/LoadingStatus';
import { SnippetViewContext } from './SnippetButtonLinkView';

const SnippetButtonLinkLoader = (props) => {
    const { id } = props;

    // load snippet code as document
    const [snippet, setSnippet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        getSnippetCode(id);
    }, [id]);

    const { setSnippetCode } = useContext(SnippetViewContext);

    const getSnippetCode = async (id) => {
        try {
            const response = await SnippetService.get_button_with_link(id);
            setSnippet(response.data);
            setSnippetCode(response.data);
            setError(null);
        } catch (err) {
            setError(err.message);
            setSnippet(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <LoadingStatus
                loading={loading}
                loadingText="Lade Snippet für Button..."
                error={error}
                errorText="Button konnte nicht geladen werden"
            />
            {snippet && <pre>{snippet}</pre>}
        </React.Fragment>
    );
};

export default SnippetButtonLinkLoader;
