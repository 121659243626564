import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';

import AsyncImage from '../Shared/AsyncImage';
import ColorEdit from './ColorEdit';
import InformationDialogButton from '../Information/InformationDialogButton';

import styles from '../WidgetEdit/Forms.module.css';

const FormAbsDimensions = ({ adjustIframeDimensions }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <div className="form-row">
            <div className="form-group col">
                <section className="input_group">
                    <div className={styles['with-info-button']}>
                        <label className="form-label" htmlFor="size_px">
                            Größe (px)
                        </label>
                        <InformationDialogButton title="Größe">
                            Durchmesser der Grafik für den Meldebutton.
                        </InformationDialogButton>
                    </div>
                    <input
                        id="size_px"
                        name="size_px"
                        type="number"
                        min="100"
                        max="400"
                        {...register('size_px', {
                            onChange: () => adjustIframeDimensions(),
                        })}
                        placeholder="800"
                        className={`form-control ${errors.size_px ? 'is-invalid' : ''}`}
                    />
                    <p className="invalid-feedback">{errors.size_px?.message}</p>
                </section>
            </div>
        </div>
    );
};

const FormDimensions = ({ setIframeHeight, setIframeWidth, computeIframeDimensions }) => {
    const { watch } = useFormContext();
    const adjustIframeDimensions = () => {
        const new_height = computeIframeDimensions(watch())[0];
        const new_width = computeIframeDimensions(watch())[1];
        setIframeHeight(new_height);
        setIframeWidth(new_width);
    };
    return (
        <React.Fragment>
            <FormAbsDimensions adjustIframeDimensions={adjustIframeDimensions} />
        </React.Fragment>
    );
};

const FormChooseHeaderImage = (props) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { headerImgs } = props;
    return (
        <section className="input_group">
            <label className="form-label" htmlFor="img_id">
                Bild für Button
            </label>
            {headerImgs && (
                <Controller
                    control={control}
                    name="img_id"
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            placeholder="Wähle Bild aus"
                            inputRef={ref}
                            options={headerImgs}
                            onChange={(val) => {
                                onChange(val.id);
                            }}
                            value={headerImgs.find((c) => c.id === value)}
                            getOptionLabel={(e) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AsyncImage src={e.uri} alt={e.id} />
                                </div>
                            )}
                        />
                    )}
                />
            )}
            <p className="invalid-feedback">{errors.img_id?.message}</p>
        </section>
    );
};

const FormHeader = (props) => {
    const { headerImgs } = props;
    return (
        <React.Fragment>
            <section className="input_group row">
                <label className="form-label" htmlFor="use_header_img"></label>
            </section>
            {<FormChooseHeaderImage headerImgs={headerImgs} />}
        </React.Fragment>
    );
};

const FormStyle = () => {
    const {
        formState: { errors },
    } = useFormContext();
    return (
        <React.Fragment>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="color_main">
                        Hauptfarbe
                    </label>
                    <InformationDialogButton title="Hauptfarbe">
                        Die Hauptfarbe wird als Füllfarbe für den Meldebutton verwendet.
                    </InformationDialogButton>
                </div>
                <ColorEdit
                    id="color_main"
                    name="color_main"
                    placeholder="#ffffff"
                    className={`color-picker form-control ${errors.color_main ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.color_main?.message}</p>
            </section>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="color_secondary">
                        Akzentfarbe
                    </label>
                    <InformationDialogButton title="Akzentfarbe">
                        Die Akzentfarbe wird als Hintergrundfarbe für den Meldebutton.
                    </InformationDialogButton>
                </div>
                <ColorEdit
                    id="color_secondary"
                    name="color_secondary"
                    placeholder="#ffffff"
                    className={`color-picker form-control ${errors.color_secondary ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.color_secondary?.message}</p>
            </section>
        </React.Fragment>
    );
};

const FormMeta = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <React.Fragment>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="link_to_formular">
                        Link zum Meldeormular
                    </label>
                    <InformationDialogButton title="Link zum Meldeformular">
                        Damit der Button auf die richtige Seite verlinkt.
                    </InformationDialogButton>
                </div>
                <input
                    id="link_to_formular"
                    name="link_to_formular"
                    {...register('link_to_formular')}
                    placeholder="https://meine-domain.de/meldeformular"
                    className={`form-control ${errors.link_to_formular ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.link_to_formular?.message}</p>
            </section>
        </React.Fragment>
    );
};

export { FormDimensions, FormStyle, FormHeader, FormMeta };
