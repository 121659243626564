import * as Yup from 'yup';

// form validation
const relDimension = Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Diese Angabe wird benötigt')
    .integer()
    .min(30, 'Minimum 30%')
    .max(100, 'Maximum 100%');
const absDimension = Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Diese Angabe wird benötigt')
    .integer()
    .min(400, 'Minimum 400px')
    .max(1200, 'Maximum 1200px');
// the backend uses only 22-char uuid for all ids
const idString = Yup.string()
    .length(22, 'Das ist keine gültige ID')
    .transform((_, val) => (val !== '' ? val : null));

const WidgetFormValidationSchema = Yup.object().shape({
    width_percent: relDimension,
    height_percent: relDimension,
    width_px: absDimension,
    height_px: absDimension,
    color_main: Yup.string()
        .required('Colorcode wird benötigt')
        .matches(/^#(?:[0-9a-f]{3}){1,2}$/i, 'Ungültiger Colorcode'),
    color_secondary: Yup.string()
        .required('Colorcode wird benötigt')
        .matches(/^#(?:[0-9a-f]{3}){1,2}$/i, 'Ungültiger Colorcode'),
    logo_id: idString.nullable(true),
    img_id: idString.nullable(true),
    title: Yup.string().required('Titel wird benötigt').min(5),
    text: Yup.string().required('Text wird benötigt').min(15),
    language: Yup.string(),
    referrer_domain: Yup.string()
        .required('Referrer-Domain wird benötigt')
        .matches(
            /^(https:\/\/)(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i,
            'Ungültige URL.',
        ),
});

export default WidgetFormValidationSchema;
