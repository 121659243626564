import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';

import AsyncImage from '../Shared/AsyncImage';
import ColorEdit from '../ButtonEdit/ColorEdit';
import InformationDialogButton from '../Information/InformationDialogButton';

import styles from './Forms.module.css';

const FormRelDimensions = ({ adjustIframeDimensions }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <div className="form-row">
            <div className="form-group col">
                <section className="input_group">
                    <label className="form-label" htmlFor="width_percent">
                        Breite (%)
                    </label>
                    <input
                        id="width_percent"
                        name="width_percent"
                        type="number"
                        {...register('width_percent', {
                            onChange: () => adjustIframeDimensions(),
                        })}
                        placeholder="100"
                        className={`form-control ${errors.width_percent ? 'is-invalid' : ''}`}
                    />
                    <p className="invalid-feedback">{errors.width_percent?.message}</p>
                </section>
                <section className="input_group">
                    <label className="form-label" htmlFor="height_percent">
                        Höhe (%)
                    </label>
                    <input
                        id="height_percent"
                        name="height_percent"
                        type="number"
                        {...register('height_percent', {
                            onChange: () => adjustIframeDimensions(),
                        })}
                        placeholder="100"
                        className={`form-control ${errors.height_percent ? 'is-invalid' : ''}`}
                    />
                    <p className="invalid-feedback">{errors.height_percent?.message}</p>
                </section>
            </div>
        </div>
    );
};

const FormAbsDimensions = ({ adjustIframeDimensions }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    return (
        <div className="form-row">
            <div className="form-group col">
                <section className="input_group">
                    <label className="form-label" htmlFor="width_px">
                        Breite (px)
                    </label>
                    <input
                        id="width_px"
                        name="width_px"
                        type="number"
                        {...register('width_px', {
                            onChange: () => adjustIframeDimensions(),
                        })}
                        placeholder="800"
                        className={`form-control ${errors.width_px ? 'is-invalid' : ''}`}
                    />
                    <p className="invalid-feedback">{errors.width_px?.message}</p>
                </section>
                <section className="input_group">
                    <label className="form-label" htmlFor="height_px">
                        Höhe (px)
                    </label>
                    <input
                        id="height_px"
                        name="height_px"
                        type="number"
                        {...register('height_px', {
                            onChange: () => adjustIframeDimensions(),
                        })}
                        placeholder="800"
                        className={`form-control ${errors.height_px ? 'is-invalid' : ''}`}
                    />
                    <p className="invalid-feedback">{errors.height_px?.message}</p>
                </section>
            </div>
        </div>
    );
};

const FormDimensions = ({ setIframeHeight, setIframeWidth, computeIframeDimensions }) => {
    const { register, watch } = useFormContext();
    const watchUseRel = watch('use_relative_dimensions');
    const adjustIframeDimensions = () => {
        const new_height = computeIframeDimensions(watch())[0];
        const new_width = computeIframeDimensions(watch())[1];
        setIframeHeight(new_height);
        setIframeWidth(new_width);
    };
    return (
        <React.Fragment>
            <section className="input_group row">
                <label className="form-label" htmlFor="use_rel">
                    Relative Dimensionen
                </label>
                <input
                    id="use_rel"
                    name="use_rel"
                    {...register('use_relative_dimensions', {
                        onChange: () => adjustIframeDimensions(),
                    })}
                    type="checkbox"
                />
            </section>
            {watchUseRel ? (
                <FormRelDimensions adjustIframeDimensions={adjustIframeDimensions} />
            ) : (
                <FormAbsDimensions adjustIframeDimensions={adjustIframeDimensions} />
            )}
        </React.Fragment>
    );
};

const FormChooseHeaderImage = (props) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const { headerImgs } = props;
    return (
        <section className="input_group">
            <label className="form-label" htmlFor="img_id">
                Headerbild
            </label>
            {headerImgs && (
                <Controller
                    control={control}
                    name="img_id"
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            placeholder="Select Option"
                            inputRef={ref}
                            options={headerImgs}
                            onChange={(val) => {
                                onChange(val.id);
                            }}
                            value={headerImgs.find((c) => c.id === value)}
                            getOptionLabel={(e) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AsyncImage src={e.uri} alt={e.id} />
                                </div>
                            )}
                        />
                    )}
                />
            )}
            <p className="invalid-feedback">{errors.img_id?.message}</p>
        </section>
    );
};

const FormHeader = (props) => {
    const { register, watch } = useFormContext();
    const watchUseHeaderImg = watch('use_custom_header_image');
    const { headerImgs } = props;
    return (
        <React.Fragment>
            <section className="input_group row">
                <label className="form-label" htmlFor="use_header_img">
                    Headerbild
                </label>
                <div className={styles['with-info-button']}>
                    <input
                        id="use_header_img"
                        name="use_header_img"
                        {...register('use_custom_header_image')}
                        type="checkbox"
                    />
                    <InformationDialogButton title="Headerbild">
                        Im Header des Meldeformulars können unsere Standardgrafik oder verschiedene Bilder verwendet
                        werden. Bei der Bildauswahl kann unter verschiedenen Bildern ausgewählt werden. Dieses Bild wird
                        dann als Hintergrundbild des Headers im Meldeformular angezeigt.
                    </InformationDialogButton>
                </div>
            </section>
            {watchUseHeaderImg && <FormChooseHeaderImage headerImgs={headerImgs} />}
        </React.Fragment>
    );
};

const FormStyle = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const preventNewlines = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    return (
        <React.Fragment>
            <section className="input_group row">
                <label className="form-label" htmlFor="use_whistle_icon">
                    Trillerpfeife
                </label>
                <div className={styles['with-info-button']}>
                    <input
                        id="use_whistle_icon"
                        name="use_whistle_icon"
                        {...register('use_whistle_icon')}
                        type="checkbox"
                    />
                    <InformationDialogButton title="Trillerpfeife">
                        Die Trillerpfeife wird neben der Überschrift im Header des Meldeformulars dargestellt.
                    </InformationDialogButton>
                </div>
            </section>
            <section className="input_group row">
                <label className="form-label" htmlFor="formal_addressing">
                    Formale Anrede
                </label>
                <div className={styles['with-info-button']}>
                    <input
                        id="formal_addressing"
                        name="formal_addressing"
                        {...register('formal_addressing')}
                        type="checkbox"
                    />
                    <InformationDialogButton title="Anrede im Meldeformular">
                        Es möglich zwischen formeller und informeller Anrede im Text des Meldeformulars zu wechseln.
                    </InformationDialogButton>
                </div>
            </section>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="title">
                        Arbeitstitel des Meldeformulars
                    </label>
                    <InformationDialogButton title="Arbeitstitel">
                        Der Arbeitstitel wird nur in der internen Übersicht angezeigt.
                    </InformationDialogButton>
                </div>
                <input
                    id="title"
                    name="title"
                    {...register('title')}
                    placeholder="Jede Meldung hilft"
                    className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.title?.message}</p>
            </section>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="text">
                        Überschrift im Meldeformular
                    </label>
                    <InformationDialogButton title="Überschrift für das Meldeformular">
                        Diese Überschrift wird im Header als Titel des Meldeformulars dargestellt wird. Die minimale
                        Länge des Textes beträgt 15 und die Maximale 100 Zeichen. Umbrüche können nicht dargestellt
                        werden.
                    </InformationDialogButton>
                </div>
                <textarea
                    id="text"
                    type="text"
                    name="text"
                    minLength="15"
                    maxLength="100"
                    {...register('text')}
                    onKeyDown={preventNewlines}
                    placeholder="Jede Meldung hilft. Melde ..."
                    className={`form-control ${errors.text ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.text?.message}</p>
            </section>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="color_main">
                        Hauptfarbe
                    </label>
                    <InformationDialogButton title="Hauptfarbe">
                        Die Farbe, die hauptsächlich im Erscheinungsbild des Meldeformulars eingesetzt wird. Der
                        Farbverlauf unter dem Header und der Absendebutton werden in der ausgewählten Farbe dargestellt.
                        Sie können den Farbwert auch als Hex-Code direkt angeben.
                    </InformationDialogButton>
                </div>
                <ColorEdit
                    id="color_main"
                    name="color_main"
                    placeholder="#ffffff"
                    className={`color-picker form-control ${errors.color_main ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.color_main?.message}</p>
            </section>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="color_secondary">
                        Akzentfarbe
                    </label>
                    <InformationDialogButton title="Akzentfarbe">
                        Die Akzentfarbe wird für den Farbverlauf im Headerbild/-Grafik in dem Meldeformular verwendet.
                    </InformationDialogButton>
                </div>
                <ColorEdit
                    id="color_secondary"
                    name="color_secondary"
                    placeholder="#ffffff"
                    className={`color-picker form-control ${errors.color_secondary ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.color_secondary?.message}</p>
            </section>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="language">
                        Sprache
                    </label>
                    <InformationDialogButton title="Sprache">
                        Auswahl der Sprache des Textes im Meldeformular. Die Überschrift des Meldeformulars muss manuell
                        übersetzt werden.
                    </InformationDialogButton>
                </div>
                <select id="language" name="language" {...register('language')}>
                    <option value="de">Deutsch</option>
                </select>
            </section>
            {/* <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="logo_id">
                        Logo-ID
                    </label>
                    <InformationDialogButton title="Logo-ID">
                        <h2>Title</h2>Content
                    </InformationDialogButton>
                </div>
                <input
                    id="logo_id"
                    name="logo_id"
                    {...register('logo_id')}
                    placeholder="fxYmuLwQzNFowd3bi5KYBW"
                    className={`form-control ${errors.logo_id ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.logo_id?.message}</p>
            </section> */}
        </React.Fragment>
    );
};

const FormMeta = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <React.Fragment>
            <section className="input_group">
                <div className={styles['with-info-button']}>
                    <label className="form-label" htmlFor="referrer_domain">
                        Domain deiner Webseite
                    </label>
                    <InformationDialogButton title="Welche Domainname wird benötigt?">
                        Hier sollte die Adresse der Webseite eingeben werden, auf der das Meldeformlar eingebunden wird.
                        Nur mit validen Domainnamen lässt sich das Formular auf deiner Webseite einbinden.
                    </InformationDialogButton>
                </div>
                <input
                    id="referrer_domain"
                    name="referrer_domain"
                    {...register('referrer_domain')}
                    placeholder="https://meine-domain.de"
                    className={`form-control ${errors.referrer_domain ? 'is-invalid' : ''}`}
                />
                <p className="invalid-feedback">{errors.referrer_domain?.message}</p>
            </section>
        </React.Fragment>
    );
};

export { FormDimensions, FormStyle, FormHeader, FormMeta };
