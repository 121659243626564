import React from 'react';
import WidgetPreview from '../Preview/WidgetPreview';
import ButtonPreview from '../Preview/ButtonPreview';

function Dashboard() {
    return (
        <main id="dashboard" lang="de">
            <section>
                <header>
                    <h2>
                        <b>Meldeformulare</b>
                    </h2>
                </header>
                <WidgetPreview></WidgetPreview>
            </section>
            <section>
                <header>
                    <h2>
                        <b>Meldebuttons</b>
                    </h2>
                </header>
                <ButtonPreview></ButtonPreview>
            </section>
        </main>
    );
}

export default Dashboard;
