import React from 'react';

const DeletingStatus = (props) => {
    const { deleting, error, success, deletingText, errorText, successText } = props;
    return (
        <>
            {deleting && <div>{deletingText}</div>}
            {error && (
                <div>
                    {errorText} - {error}
                </div>
            )}
            {success && <div>{successText}</div>}
        </>
    );
};

export default DeletingStatus;
