import api from '../api';

const getAll = async () => {
    return await api.get(`buttons`);
};

const get = async (id) => {
    return await api.get(`buttons/${id}`);
};

const create = async (data) => {
    return await api.post(`buttons`, data);
};

const edit = async (id, data) => {
    return await api.put(`buttons/${id}`, data);
};

const remove = async (id) => {
    return await api.delete(`buttons/${id}`);
};

const preview = async (data) => {
    return await api.post(`buttons/preview`, data);
};

const defaultParameters = {
    color_main: '#0F5319',
    color_secondary: '#006CB8',
    height_percent: 90,
    height_px: 128,
    text: 'Jede Meldung hilft im Kampf gegen Antisemitismus',
    use_relative_dimensions: false,
    title: 'Makkabi',
    width_percent: 80,
    width_px: 128,
};

const ButtonService = {
    getAll,
    get,
    create,
    edit,
    remove,
    preview,
    defaultParameters,
};

export default ButtonService;
