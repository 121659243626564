import React from 'react';
import { useState, useEffect } from 'react';

import ButtonService from '../../services/button.service';
import LoadingStatus from '../Shared/LoadingStatus';

// preview button
const PreviewButton = (props) => {
    // widget parameters
    const { params, height, width } = props;

    // // load rendered widget as document
    const [document, setDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        getRenderedDocument(params);
    }, [params]);

    const getRenderedDocument = async (params) => {
        try {
            const response = await ButtonService.preview(params);
            setDocument(response.data);
            setError(null);
        } catch (err) {
            setError(err.message);
            setDocument(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <LoadingStatus
                loading={loading}
                loadingText="Lade Widgetpreview ..."
                error={error}
                errorText="Button Vorschau konnte nicht geladen werden"
            />
            {document && <iframe srcDoc={document} height={height} width={width}></iframe>}
        </React.Fragment>
    );
};

export default PreviewButton;
