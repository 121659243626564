import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import UserService from '../../services/user.service';

import styles from './Account.module.css';

/**
 * @param {Object} props
 * @param {string} props.header
 * @param {string} props.value
 */
function TableRow(props) {
    return (
        <tr>
            <th>{props.header}:</th>
            <td>{props.value}</td>
        </tr>
    );
}

/**
 * @param {Object} props
 * @param {string} props.header
 * @param {string} props.value
 * @param {() => void} props.onClick
 */
function ClipboardTableRow(props) {
    const onClick = () => {
        navigator.clipboard.writeText(props.value);
        props.onClick();
    };
    return (
        <tr className={styles['clipboard']}>
            <th>{props.header}:</th>
            <td>
                <button onClick={onClick} title="Zu Clipboard kopieren" aria-label="API Key zu Clipboard kopieren">
                    <span>{props.value}</span>
                    <BiCopy />
                </button>
            </td>
        </tr>
    );
}

function Account() {
    const [user, setUser] = useState('');
    const [showClipboardAlert, setShowClipboardAlert] = useState(false);

    useEffect(() => {
        getUser();
    }, []);

    const getUser = () => {
        UserService.profile().then((resp) => {
            setUser(resp.data);
        });
    };

    useEffect(() => {
        if (!showClipboardAlert) return;

        setTimeout(() => {
            setShowClipboardAlert(false);
        }, 3000);
    }, [showClipboardAlert]);

    const formattedJoinedDate = useMemo(() => {
        if (!user) return;

        const date = Date.parse(user.date_joined);
        if (isNaN(date)) return user.date_joined;

        return Intl.DateTimeFormat(undefined, {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }).format(new Date(user.date_joined));
    }, [user.date_joined]);

    return (
        <main className={styles['account-page']}>
            {user && (
                <>
                    <table className={styles['account-details']}>
                        <tbody>
                            <TableRow header="Benutzername" value={user.username} />
                            <TableRow header="E-Mail" value={user.email} />
                            <TableRow header="Rolle" value={user.roles} />
                            <TableRow header="Dabei seit" value={formattedJoinedDate} />
                            <ClipboardTableRow
                                header="Api Key"
                                value={user.api_key}
                                onClick={() => setShowClipboardAlert(true)}
                            />
                            {/* <tr className={styles['button-row']}> */}
                            {/*     <td> */}
                            {/*         <button className="button-primary" type="button"> */}
                            {/*             Neues Passwort anfordern */}
                            {/*         </button> */}
                            {/*     </td> */}
                            {/* </tr> */}
                        </tbody>
                    </table>

                    <div
                        aria-live="polite"
                        aria-hidden={!showClipboardAlert}
                        className={`${styles['clipboard-copy-information']} ${
                            showClipboardAlert ? styles['open'] : ''
                        }`}
                    >
                        Zum Clipboard kopiert
                    </div>
                </>
            )}
            <ul>
                <li>
                    Bei Problemen melden Sie sich bitte bei <b>meldeformular@rias-bund.de</b>
                </li>
            </ul>
        </main>
    );
}

export default Account;
