import React from 'react';

const SavingStatus = (props) => {
    const { saving, error, success, savingText, errorText, successText } = props;
    return (
        <>
            {saving && <div>{savingText}</div>}
            {error && (
                <div>
                    {errorText} - {error}
                </div>
            )}
            {success && <div>{successText}</div>}
        </>
    );
};

export default SavingStatus;
