import React from 'react';

const LoadingStatus = (props) => {
    const { loading, error, loadingText, errorText } = props;
    return (
        <>
            {loading && <div>{loadingText}</div>}
            {error && (
                <div className={props.className}>
                    {errorText} - {error}
                </div>
            )}
        </>
    );
};

export default LoadingStatus;
