import api from '../api';

const get = async (id) => {
    return await api.get(`snippets/widget/${id}`);
};

const get_button = async (id) => {
    return await api.get(`snippets/button/${id}`);
};

const get_button_with_link = async (id) => {
    return await api.get(`snippets/buttonWithLink/${id}`);
};

const get_snippet_with_banner = async (id) => {
    return await api.get(`snippets/widget_with_banner/${id}`);
};

const SnippetService = {
    get,
    get_button,
    get_snippet_with_banner,
    get_button_with_link,
};

export default SnippetService;
