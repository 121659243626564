import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import ButtonService from '../../services/button.service';
import AssetsService from '../../services/assets.service';
import { delay } from '../../utils';
import PreviewButton from './PreviewButton';
import { FormDimensions, FormStyle, FormMeta } from './Forms';
import ButtonFormValidationSchema from './FormValidation';
import LoadingStatus from '../Shared/LoadingStatus';
import SavingStatus from '../Shared/SavingStatus';
import useFormDirtyStateWarning from '../WidgetEdit/hooks/useFormDirtyStateWarning';

import styles from '../WidgetEdit/WidgetEdit.module.css';

const ButtonEdit = () => {
    const { id } = useParams();

    // get persisted button parameters
    const [button, setButton] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(null);

    const updateDimensions = (data) => {
        const dimensions = computeIframeDimensions(data);
        setIframeHeight(dimensions[0]);
        setIframeWidth(dimensions[1]);
    };

    const getButtonParameters = async () => {
        try {
            const response = await ButtonService.get(id);

            setButton(response.data);
            setPreview(response.data);
            // initial iframe dimensions
            updateDimensions(response.data);
            setLoadingError(null);
        } catch (err) {
            setLoadingError(err.message);
            setButton(null);
        } finally {
            setLoading(false);
        }
    };

    // get available header images (their ids)
    const [headerImgs, setHeaderImgs] = useState(null);
    const [loadingHI, setLoadingHI] = useState(true);
    const [loadingHIError, setLoadingHIError] = useState(null);
    const getHeaderImgIds = async () => {
        try {
            const response = await AssetsService.getHeaderImages();
            setHeaderImgs(response.data);
            setLoadingHIError(null);
        } catch (err) {
            setLoadingHIError(err.message);
            setHeaderImgs(null);
        } finally {
            setLoadingHI(false);
        }
    };

    useEffect(() => {
        getButtonParameters();
        getHeaderImgIds();
    }, []);

    // iframe parameters
    const computeIframeDimensions = (params) => {
        return [params.height_px, params.width_px];
    };
    const [iframeHeight, setIframeHeight] = useState();
    const [iframeWidth, setIframeWidth] = useState();

    // setup form
    const methods = useForm({
        defaultValues: {
            ...button,
            size_px: button?.width_px,
        },
        resolver: yupResolver(ButtonFormValidationSchema),
        reValidateMode: 'onChange',
        mode: 'onChange',
    });
    const {
        reset,
        handleSubmit,
        formState: { isDirty },
        getValues,
    } = methods;

    useEffect(() => {
        reset({
            ...button,
            size_px: button?.width_px,
        });
    }, [reset, button]);

    // save button, i.e. submit form data to backend
    const [saving, setSaving] = useState(false);
    const [savingText, setSavingText] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveSuccessText, setSaveSuccessText] = useState(null);
    const [saveError, setSaveError] = useState(false);
    const [saveErrorText, setSaveErrorText] = useState(null);
    const putButtonParameters = async (data) => {
        try {
            setSaving(true);
            setSavingText(`Speichere Button ...`);
            await delay(1000);
            await ButtonService.edit(id, {
                ...data,
                width_px: data.size_px,
                height_px: data.size_px,
                use_rel_dimensions: false,
            });
            setSaveSuccess(true);
            setSaveSuccessText(`Button gespeichert.`);
        } catch (err) {
            setSaveError(err.code);
            setSaveErrorText(`Button konnte nicht gespeichert werden.`);
            await delay(1000);
        } finally {
            setSaving(false);
            getButtonParameters();
            await delay(1000);
            setSaveSuccess(null);
            setSaveError(null);
        }
    };

    const { DirtyStateWarningModal } = useFormDirtyStateWarning(isDirty, async () => {
        const values = getValues();
        await putButtonParameters(values);
    });

    const generatePreview = ({ size_px, ...formData }) => {
        const actualData = {
            ...formData,
            width_px: size_px,
            height_px: size_px,
        };
        setPreview(actualData);
        updateDimensions(actualData);
    };

    return (
        <React.Fragment>
            <DirtyStateWarningModal />
            <aside>
                <LoadingStatus
                    loading={loading}
                    loadingText={`Lade Parameter für Button ${id}`}
                    error={loadingError}
                    errorText={`Parameter für Button ${id} konnten nicht geladen werden`}
                    className="content-padding"
                />
                <LoadingStatus
                    loading={loadingHI}
                    loadingText="Lade IDs der verfügbaren Headerbilder"
                    error={loadingHIError}
                    errorText="IDs der Headerbilder konnten nicht geladen werden"
                    className="content-padding"
                />
                {button && (
                    <React.Fragment>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(putButtonParameters)} className="content-padding">
                                <div className={styles['form-grid']}>
                                    <h3>
                                        <b>Meta</b>
                                    </h3>
                                    <FormMeta />
                                    <h3>
                                        <b>Größenparameter</b>
                                    </h3>
                                    <FormDimensions
                                        setIframeHeight={setIframeHeight}
                                        setIframeWidth={setIframeWidth}
                                        computeIframeDimensions={computeIframeDimensions}
                                    />
                                    <h3>
                                        <b>Gestaltung</b>
                                    </h3>
                                    <FormStyle headerImgs={headerImgs} />
                                    {
                                        <div className="button_group">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    const values = methods.getValues();
                                                    generatePreview(values);
                                                }}
                                            >
                                                Vorschau
                                            </button>
                                            <button type="submit">Speichern</button>
                                            <button
                                                type="button"
                                                className="reset"
                                                onClick={() => {
                                                    reset();
                                                    generatePreview(methods.getValues());
                                                }}
                                            >
                                                Zurücksetzen
                                            </button>
                                        </div>
                                    }
                                </div>
                            </form>
                        </FormProvider>
                    </React.Fragment>
                )}
                <SavingStatus
                    saving={saving}
                    savingText={savingText}
                    error={saveError}
                    errorText={saveErrorText}
                    success={saveSuccess}
                    successText={saveSuccessText}
                />
            </aside>
            <main id="canvas">
                {preview && <PreviewButton params={preview} height={iframeHeight} width={iframeWidth} />}
            </main>
        </React.Fragment>
    );
};

export default ButtonEdit;
