import React, { createContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SnippetButtonLinkLoader from './SnippetButtonLinkLoader';

import styles from './SnippetView.module.css';

export const SnippetViewContext = createContext({
    setSnippetCode: () => undefined,
});

const SnippetButtonLinkView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [snippetCode, setSnippetCode] = useState(null);

    const download = () => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';

        const blob = new Blob([snippetCode], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'svg-button.html';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <React.Fragment>
            <main className={styles['snippet-view']} id="snippet">
                <section>
                    <header>
                        <h2>
                            <b>Einbettungscode für den Meldebutton</b>
                        </h2>
                    </header>
                    <ul>
                        <li>
                            Wenn der Meldebutton für das Meldeformular auf eine andere Unterseite verweisen soll, kannst
                            du im Meldebutton-Creator unter &quot;edit, die URL anpassen.&quot;
                        </li>
                        <br></br>
                        <li>
                            Wenn du diesen Code auf deiner Seite einbindest, verlinkt er auf die von dir angegebene URL.
                            Die SVG-Datei verwendet außerdem die von dir angegebenen Farben und Größe.
                        </li>
                    </ul>
                    <SnippetViewContext.Provider
                        value={{
                            setSnippetCode,
                        }}
                    >
                        <SnippetButtonLinkLoader id={id}></SnippetButtonLinkLoader>
                    </SnippetViewContext.Provider>
                </section>
                <section className="button_group">
                    <button onClick={() => navigate(-1)}>Zurück</button>
                    <button onClick={download}>Code Herunterladen</button>
                </section>
            </main>
        </React.Fragment>
    );
};

export default SnippetButtonLinkView;
