import React, { createContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SnippetButtonLoader from './SnippetButtonLoader';

import styles from './SnippetView.module.css';

export const SnippetViewContext = createContext({
    setSnippetCode: () => undefined,
});

const SnippetButtonView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [snippetCode, setSnippetCode] = useState(null);

    const download = () => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';

        const blob = new Blob([snippetCode], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'rias-widget-button.svg';
        a.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <React.Fragment>
            <main className={styles['snippet-view']} id="snippet">
                <section>
                    <header>
                        <h2>
                            <b>Einbettungscode für den Meldebutton</b>
                        </h2>
                    </header>
                    <h3>
                        Über den &quot;Herunterladen&quot;-Button, kannst du den SVG-Code des Meldebuttons
                        herunterladen.
                    </h3>
                    <ul>
                        <li>
                            Um auf das Meldeformular zu verlinken, kannst du den SVG-Code als Content eines HTML-Links
                            verwenden.
                        </li>
                        <li>
                            Entweder über die Id des Iframes, wenn sich der Button auf der gleichen Seite, wie das
                            Formular befindet:
                        </li>
                    </ul>
                    <pre>&lt;a href=&quot;#[id-des-iframes]&quot;&gt;[SVG-Code]&lt;/a&gt;</pre>
                    <ul>
                        <li>Oder über Angabe der URL auf deiner Seite, auf der du das Meldeformular einbindest:</li>
                    </ul>
                    <pre>&lt;a href=&quot;https://meine-domain/meldeformular&quot;&gt;[SVG-Code]&lt;/a&gt;</pre>
                    <ul>
                        <li>
                            Wenn du in der Übersicht des Meldebuttons auf &quot;Button mit Link&quot; klickst, kannst du
                            Code herunterladen, in dem der SVG-Code schon in einem Link eingebunden ist.
                        </li>
                    </ul>
                    <br></br>
                    <h3>
                        <b>SVG-Code</b>
                    </h3>
                    <SnippetViewContext.Provider
                        value={{
                            setSnippetCode,
                        }}
                    >
                        <SnippetButtonLoader id={id}></SnippetButtonLoader>
                    </SnippetViewContext.Provider>
                </section>
                <section className="button_group">
                    <button onClick={() => navigate(-1)}>Zurück</button>
                    <button onClick={download}>SVG-Code herunterladen</button>
                </section>
            </main>
        </React.Fragment>
    );
};

export default SnippetButtonView;
