import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import Layout from './components/Layout';
import Help from './components/Help/Help';
import Account from './components/Account/Account';
import Dashboard from './components/Dashboard/Dashboard';
import Users from './components/Users/Users';
import Login from './components/Login/Login';
import NotFound from './components/NotFound';
import WidgetEdit from './components/WidgetEdit/WidgetEdit';
import ButtonEdit from './components/ButtonEdit/ButtonEdit';
import SnippetView from './components/SnippetView/SnippetView';
import SnippetViewWithBanner from './components/SnippetView/SnippetViewWithBanner';
import SnippetButtonView from './components/SnippetView/SnippetButtonView';
import SnippetButtonLinkView from './components/SnippetView/SnippetButtonLinkView';
import WidgetsOverview from './components/widgetsoverview/WidgetsOverview';
// import Develop from './components/Develop/Develop';
import ProtectedRoutes from './routes/ProtectedRoutes';
import RoleBasedRoutes from './routes/RoleBasedRoutes';

import './style.css';

const App = createRoutesFromElements(
    <>
        <Route element={<ProtectedRoutes />}>
            <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="editor/:id" element={<WidgetEdit />} />
                <Route path="button/:id" element={<ButtonEdit />} />
                <Route path="snippet/widget/:id" element={<SnippetView />} />
                <Route path="snippetWithBanner/widget/:id" element={<SnippetViewWithBanner />} />
                <Route path="snippet/button/:id" element={<SnippetButtonView />} />
                <Route path="snippet/buttonWithLink/:id" element={<SnippetButtonLinkView />} />
                <Route path="help" element={<Help />} />
                <Route path="account" element={<Account />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route
                    path="users"
                    element={
                        <React.Suspense fallback={<Dashboard />}>
                            <RoleBasedRoutes roles={['admin']}>
                                <Users />
                            </RoleBasedRoutes>
                        </React.Suspense>
                    }
                />
                <Route
                    path="widgets"
                    element={
                        <React.Suspense fallback={<Dashboard />}>
                            <RoleBasedRoutes roles={['admin', 'statistic']}>
                                <WidgetsOverview />
                            </RoleBasedRoutes>
                        </React.Suspense>
                    }
                />
                {/*<Route path="develop" element={<Develop />} />*/}
            </Route>
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
    </>,
);

export default App;
