import axios from 'axios';

const config = {
    baseURL: process.env.REACT_APP_API_URL + '/api/',
    headers: {
        ContentType: 'application/json',
    },
};

const api = axios.create(config);

api.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('access_token');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            delete api.defaults.headers.common.Authorization;
        }
        return config;
    },

    (error) => Promise.reject(error),
);

export default api;
