import { React, Fragment } from 'react';
import { useEffect, useState } from 'react';

//import { Navigate } from 'react-router-dom';

import UserService from '../services/user.service';

import AccessDenied from '../components/AccessDenied';

const RoleBasedRoutes = ({ children, roles }) => {
    const [userroles, setUserroles] = useState(undefined);

    const checkUserRoles = () => {
        UserService.roles().then((resp) => {
            setUserroles(resp.data);
        });
    };

    useEffect(() => {
        checkUserRoles();
    }, []);

    if (userroles === undefined) {
        return null;
    }

    const canAccess = userroles.some((userRole) => roles.includes(userRole));

    if (canAccess) return <Fragment>{children}</Fragment>;
    if (!canAccess) return <AccessDenied />;
};

export default RoleBasedRoutes;
