import React from 'react';
import { useState, useEffect } from 'react';

import WidgetService from '../../services/widget.service';
import LoadingStatus from '../Shared/LoadingStatus';
import DeletingStatus from '../Shared/DeletingStatus';
import SavingStatus from '../Shared/SavingStatus';
import { delay } from '../../utils';
import Modal from '../Modal/Modal';
import WidgetPreviewItem from './WidgetPreviewItem';
import WidgetPreviewCreateItem from './WidgetPreviewCreateItem';
import PreviewIframe from '../WidgetEdit/PreviewIframe';

import styles from './WidgetPreview.module.css';

const WidgetPreview = () => {
    // load all widgets
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState(null);
    const getWidgetParameters = async () => {
        try {
            const response = await WidgetService.getAll();
            await response.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            setData(response.data);
            setLoadError(null);
        } catch (err) {
            setLoadError(err.message);
            setData(null);
        } finally {
            setLoading(false);
            await delay(1000);
            setLoadError(null);
        }
    };

    // create new widget from default parameters
    const defaultParams = WidgetService.defaultParameters;
    const [saving, setSaving] = useState(false);
    const [savingText, setSavingText] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveSuccessText, setSaveSuccessText] = useState(null);
    const [saveError, setSaveError] = useState(false);
    const [saveErrorText, setSaveErrorText] = useState(null);
    const postWidgetParameters = async (data) => {
        try {
            setSaving(true);
            setSavingText(`Erstelle neues Meldeformular ...`);
            await delay(1000);
            await WidgetService.create(data);
            setSaveSuccess(true);
            setSaveSuccessText(`Meldeformular erstellt.`);
        } catch (err) {
            setSaveError(err.code);
            setSaveErrorText(`Meldeformular konnte nicht ersellt werden.`);
            await delay(1000);
        } finally {
            setSaving(false);
            getWidgetParameters();
            await delay(1000);
            setSaveSuccess(null);
            setSaveError(null);
        }
    };

    // delete widget
    const [deleting, setDeleting] = useState(false);
    const [deletingText, setDeletingText] = useState(null);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteSuccessText, setDeleteSuccessText] = useState(null);
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorText, setDeleteErrorText] = useState(null);
    const deleteWidgetParamsById = async (id) => {
        try {
            setDeleting(true);
            setDeletingText(`Lösche Meldeformular ${id} ...`);
            await delay(1000);
            await WidgetService.remove(id);
            setDeleteSuccess(true);
            setDeleteSuccessText(`Meldeformular ${id} erfolgreich gelöscht`);
        } catch (err) {
            setDeleteError(err.message);
            setDeleteErrorText(`Meldeformular ${id} konnte nicht gelöscht werden.`);
            await delay(1000);
        } finally {
            setDeleting(false);
            getWidgetParameters();
            await delay(1000);
            setDeleteSuccess(null);
            setDeleteError(null);
        }
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState();

    const handleDeleteClick = (id) => {
        setShowDeleteModal(true);
        setIdToDelete(id);
    };

    const handleDelete = () => {
        // delete
        deleteWidgetParamsById(idToDelete);

        // close the modal
        setShowDeleteModal(false);
        setIdToDelete();
    };

    const handleDeleteCancel = () => {
        // close the modal
        setShowDeleteModal(false);
        setIdToDelete();
    };

    useEffect(() => {
        getWidgetParameters();
    }, []);

    return (
        <React.Fragment>
            <Modal
                show={showDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleDeleteCancel}
                title="Lösche Meldeformular"
                text="Möchten sie das Meldeformular wirklich löschen?"
                confirmButtonLabel="Löschen"
                cancelButtonLabel="Abbrechen"
            />
            <ul id="widget_preview" className="list_grid">
                {data &&
                    data.map((widget) => (
                        <React.Fragment key={widget.id}>
                            <WidgetPreviewItem widget={widget} onDelete={handleDeleteClick} />
                            <span key={`preview-${widget.id}`} className={styles['iframe-preview']}>
                                <PreviewIframe params={widget} height="100%" width="100%" />
                            </span>
                        </React.Fragment>
                    ))}
                <WidgetPreviewCreateItem onClick={() => postWidgetParameters(defaultParams)} />
            </ul>
            <LoadingStatus
                loading={loading}
                loadingText="Lade das Meldeformular ..."
                error={loadError}
                errorText="Das Meldeformular konnten nicht geladen werden."
            />
            <DeletingStatus
                deleting={deleting}
                deletingText={deletingText}
                error={deleteError}
                errorText={deleteErrorText}
                success={deleteSuccess}
                successText={deleteSuccessText}
            />
            <SavingStatus
                saving={saving}
                savingText={savingText}
                error={saveError}
                errorText={saveErrorText}
                success={saveSuccess}
                successText={saveSuccessText}
            />
        </React.Fragment>
    );
};

export default WidgetPreview;
