import React from 'react';

import helpFileDe from './files/de/RIAS_Widget_Anleitung_10-06-2024.pdf';

function Help() {
    return (
        <main>
            <h2>
                <b>Einbinden des Meldeformulars</b>
            </h2>
            <ul>
                <li>
                    Du kannst die Anleitung, wie du das Meldeformular und den Meldebutton einbinden kannst,{' '}
                    <a href={helpFileDe} target="_blank" rel="noreferrer">
                        <b>hier</b>
                    </a>{' '}
                    herunterladen.
                </li>
                <br></br>
                <li>
                    Wende dich bei Fragen jederzeit an <b>meldeformular@rias-bund.de</b>
                </li>
            </ul>
        </main>
    );
}

export default Help;
