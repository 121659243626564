import React, { useCallback, useEffect, useState, useRef } from 'react';

/**
 * As we're using some modals with an animation delay, we can't focus on them
 * until the animation has complete. For this we're using this function which
 * will attempt to focus on the given element, and keep trying until either
 * the time limit has run out, or the element was successfully focussed.
 */
function attemptFocus(element) {
    element.focus();
    if (document.activeElement == element) return;

    const attemptInterval = 100;
    const maxAttemptTime = 1500;
    let currentTime = 0;
    const timer = setInterval(() => {
        currentTime += attemptInterval;
        element.focus();
        if (document.activeElement == element || currentTime >= maxAttemptTime) clearInterval(timer);
    }, attemptInterval);
}

/**
 * @param {Object} props
 * @param {boolean} props.show
 * @param {() => void} props.onConfirm
 * @param {() => void} props.onCancel
 * @param {() => Promise<void>} props.onAltButtonClick
 * @param {string} props.title
 * @param {string} props.text
 * @param {string} props.confirmButtonLabel
 * @param {string} props.cancelButtonLabel
 * @param {string} props.altButtonLabel
 */
const Modal = (props) => {
    const shouldShowAltButton = props.altButtonLabel != null;
    const dialogRef = useRef(null);

    // IMPORTANT, should the tab order of the modal buttons be changed,
    // or new focusable items added before or after the current first
    // and last focusable items, these refs should be reassigned accordingly
    const firstFocus = useRef(null);
    const lastFocus = useRef(null);

    const trapFocus = useCallback(
        (event) => {
            const focusTarget = event.target;

            const focusIsWithinDialog = dialogRef.current.contains(focusTarget);

            if (focusIsWithinDialog) return;

            if (event.relatedTarget == firstFocus.current) {
                lastFocus.current.focus();
            } else {
                firstFocus.current.focus();
            }
        },
        [dialogRef, firstFocus, lastFocus],
    );

    useEffect(() => {
        if (!props.show) return;
        document.addEventListener('focus', trapFocus, true);

        return () => {
            document.removeEventListener('focus', trapFocus, true);
        };
    }, [props.show, trapFocus]);

    const [returnFocusTo, setReturnFocusTo] = useState(null);
    useEffect(() => {
        if (props.show) {
            setReturnFocusTo(document.activeElement);
            attemptFocus(firstFocus.current);
        } else if (returnFocusTo != null) {
            returnFocusTo.focus();
        }
    }, [props.show]);

    const handleKeydown = (event) => {
        const key = event.key;

        if (key === 'Escape') {
            props.onCancel();
        }
    };

    return (
        <div
            role="dialog"
            aria-labelledby="dialog-title"
            ref={dialogRef}
            className={`modal ${props.show ? 'show' : ''}`}
            onKeyDown={handleKeydown}
        >
            <div className="modal-content">
                <h2 id="dialog-title">{props.title}</h2>
                <p>{props.text}</p>
                <div className="modal-buttons">
                    <button ref={firstFocus} onClick={props.onConfirm}>
                        {props.confirmButtonLabel}
                    </button>
                    {shouldShowAltButton && <button onClick={props.onAltButtonClick}>{props.altButtonLabel}</button>}
                    <button ref={lastFocus} onClick={props.onCancel}>
                        {props.cancelButtonLabel}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
