import React from 'react';

function AccessDenied() {
    return (
        <main lang="de">
            <article>
                <header>
                    <h2>
                        <b>You have no permission to access this page.</b>
                    </h2>
                </header>
                <p>
                    <b>You can now return to:</b>
                </p>
                <p>
                    <a href="/dashboard">Dashboard</a>
                </p>
            </article>
        </main>
    );
}

export default AccessDenied;
