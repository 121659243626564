import React from 'react';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import styles from './PreviewItem.module.css';

function ButtonPreviewItem(props) {
    const { button } = props;
    return (
        <li key={button.id} className={styles.item}>
            <header className="column justify_between">
                <div className={styles.header}>
                    <h4 className={styles.headline}>
                        <b>Meldebutton</b>
                    </h4>
                    <Link
                        to={`/button/${button.id}`}
                        type="button"
                        className={`${styles.button} ${styles['primary-button']}`}
                    >
                        <b className={styles['button-text']}>Bearbeiten</b>
                        <span>
                            <FaRegEdit />{' '}
                        </span>
                    </Link>
                </div>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>Link zum Meldeformular</td>
                            <td>
                                <b>{button.link_to_formular}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Größe</td>
                            <td>
                                <b>{button.width_px}px</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Hauptfarbe</td>
                            <td>
                                <b>{button.color_main}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Akzentfarbe</td>
                            <td>
                                <b>{button.color_secondary}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </header>
            <section className={styles.footer}>
                <Link className={styles.button} to={`/snippet/buttonWithLink/${button.id}`} type="button">
                    <b>&lt;Button mit Link&gt;</b>
                </Link>
                <Link className={styles.button} to={`/snippet/button/${button.id}`} type="button">
                    <b>&lt;SVG Code &gt;</b>
                </Link>
                <button
                    type="button"
                    className={styles.button}
                    onClick={() => {
                        props.onDelete(button.id);
                    }}
                >
                    {' '}
                    <b className={styles['button-text']}>Löschen</b>
                    <span>
                        <FaRegTrashAlt />{' '}
                    </span>
                </button>
            </section>
        </li>
    );
}

export default ButtonPreviewItem;
