import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { IoCloseCircle } from 'react-icons/io5';

import styles from './InformationDialogButton.module.css';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.children
 */
function InformationDialogButton(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const buttonAriaLabel = useMemo(() => (modalOpen ? 'Information schließen' : 'Mehr Information'), [modalOpen]);

    /** @type {React.MutableRefObject<HTMLDialogElement>} */
    const dialogRef = useRef(null);

    /** @type {React.MutableRefObject<HTMLButtonElement>} */
    const closeButtonRef = useRef(null);

    useEffect(() => {
        if (dialogRef.current == null) return;

        if (modalOpen) dialogRef.current.show();
        else dialogRef.current.close();
    }, [modalOpen]);

    const handleDialogKeydown = (event) => {
        const key = event.key;
        if (key === 'Escape') setModalOpen(false);

        if (key === 'Tab') {
            closeButtonRef.current.focus();
            event.preventDefault();
        }
    };

    return (
        <>
            <button
                className={`${styles['info-button']} button-icon`}
                aria-label={buttonAriaLabel}
                type="button"
                onClick={() => setModalOpen(!modalOpen)}
            >
                <FaInfoCircle />
            </button>
            <dialog
                className={`${styles['modal-overlay']} modal-content`}
                role="dialog"
                aria-live="polite"
                ref={dialogRef}
                open={false}
                aria-labelledby="dialog-referring-domain"
                onKeyDown={handleDialogKeydown}
            >
                <div className={styles['label-with-button']}>
                    <label className="form-label">{props.title}</label>
                    <div className={styles['button-container']}>
                        <span>Schließen</span>
                        <button
                            className={`${styles['info-button']} button-icon`}
                            aria-label="Information schließen"
                            type="button"
                            onClick={() => setModalOpen(!modalOpen)}
                            ref={closeButtonRef}
                        >
                            <IoCloseCircle />
                        </button>
                    </div>
                </div>
                {props.children}
            </dialog>
        </>
    );
}

export default InformationDialogButton;
