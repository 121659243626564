import React from 'react';
import { useEffect, useRef } from 'react';
import { WidgetInstance } from 'friendly-challenge';

const FriendlyCaptcha = ({ onCaptchaSolved }) => {
    const container = useRef();
    const widget = useRef();

    const doneCallback = (solution) => {
        onCaptchaSolved(solution);
    };

    const errorCallback = (err) => {
        console.log('There was an error when trying to solve the Captcha.');
        console.log(err);
    };

    useEffect(() => {
        if (!widget.current && container.current) {
            widget.current = new WidgetInstance(container.current, {
                startMode: 'auto',
                doneCallback: doneCallback,
                errorCallback: errorCallback,
            });
        }
        return () => {
            if (widget.current != undefined) widget.current.reset();
        };
    }, [container]);

    return <div ref={container} className="frc-captcha" data-sitekey="FCMMG09U8I7MO59Q" />;
};

export default FriendlyCaptcha;
