import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SnippetLoader from './SnippetLoader';

import styles from './SnippetView.module.css';

const SnippetView = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <main className={styles['snippet-view']} id="snippet">
                <section>
                    <header>
                        <h2>
                            <b>Einbettungscode</b>
                        </h2>
                        <h3>für Meldeformular {id}</h3>
                    </header>
                    <p>Füge folgenden Code in deiner html-Seite ein:</p>
                    <SnippetLoader id={id}></SnippetLoader>
                </section>
                <section>
                    <h3>Erklärung der Parameter des Iframes</h3>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <td>id</td>
                                <td>
                                    dieses Feld wird zur Funktionsweise des Meldeformulars nicht gebraucht. Du kannst es
                                    jedoch benutzen, um vom Meldebutton auf das Meldeformular zu verlinken.
                                    Standardmäßig ist hier die ID des Meldeformulars angegeben, du kannst die ID aber
                                    auch einfach verändern.
                                </td>
                            </tr>
                            <tr>
                                <td>style</td>
                                <td>
                                    hier werden css-Attribute dem Iframe hinzugefügt. Diese können von dir angepasst
                                    oder ergänzt werden.
                                </td>
                            </tr>
                            <tr>
                                <td>title</td>
                                <td>
                                    Dieses Feld wird von Screenreadern verwendet. Du kannst den Inhalt davon verändern.
                                </td>
                            </tr>
                            <tr>
                                <td>referrerpolicy</td>
                                <td>
                                    Die Domain deiner Webseite wird an das Iframe gesendet. Dies ist eine
                                    Sicherheitsvorkehrung, dass nur die Domain, die in den Einstellungen des
                                    Meldeformulars konfiguriert wurde, das Meldeformular auch einbinden kann. Es ist
                                    daher wichtig, dass du dies nur in den Einstellungen des Meldeformular
                                    konfigurierst. Bitte nicht im Iframe Code verändern.
                                </td>
                            </tr>
                            <tr>
                                <td>src</td>
                                <td>
                                    Auch diesen Wert solltest du nicht verändern. Die URL setzt sich zusammen aus der ID
                                    deines Meldeformulars, sowie einem key (rechts von &quot;?jwt=&quot;), der dafür
                                    sorgt, dass du nur die von dir erzeugten Meldeformulare (und nicht die von anderen
                                    User*innen) einbinden kannst. Den Key findest du auch unter &quot;api-key&quot; in
                                    deiner Accountseite, du brauchst mit diesem aber nichts zu tun. Sollte das
                                    Meldeformular nicht richtig angezeigt werden, kannst du überprüfen, ob der Wert der
                                    gleiche ist.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section className="button_group">
                    <button onClick={() => navigate(-1)}>Zurück</button>
                </section>
            </main>
        </React.Fragment>
    );
};

export default SnippetView;
