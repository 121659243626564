import api from '../api';

const headerImageBase = `${api.defaults.baseURL}images/thumb/`;

const getHeaderImages = async () => {
    return await api.get(`images/`);
};

const AssetsService = {
    getHeaderImages,
    headerImageBase,
};

export default AssetsService;
