import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

import passwords from '../../utils/passwords';

import UserService from '../../services/user.service';

const PASSWORD_LENGTH = 16;

const AddUserForm = ({ show, onCancel, onUpdate }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(passwords.generate(PASSWORD_LENGTH));
    const [roles, setRoles] = useState('user;');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleRolesChange = (event) => {
        setRoles(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleGeneratePassword = (event) => {
        event.preventDefault();
        setPassword(passwords.generate(PASSWORD_LENGTH));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // logic to create a user
        await UserService.create({ username: username, password: password, email: email, roles: roles });

        setUsername('');
        setEmail('');
        setPassword(passwords.generate(PASSWORD_LENGTH));
        setRoles('user;');
        onUpdate();
    };

    return (
        <form className={`addUserForm ${show ? 'show' : ''}`} onSubmit={handleSubmit}>
            <div className="addUserFormContainer">
                <label className="form-label inputContainer">
                    Benutzername:
                    <input type="text" value={username} onChange={handleUsernameChange} />
                </label>
                <label className="form-label inputContainer">
                    E-Mail:
                    <input className="emailfield" type="email" value={email} onChange={handleEmailChange} />
                </label>
                <label className="form-label inputContainer">
                    Password:
                    <input type="text" value={password} onChange={handlePasswordChange} readOnly />
                    <button onClick={handleGeneratePassword}>Generate Password</button>
                </label>
                <label className="form-label inputContainer">
                    Roles:
                    <input type="text" id="roles_input" value={roles} onChange={handleRolesChange} />
                </label>
            </div>
            <div className="inputContainer">
                <Button id="cancel_button" variant="danger" onClick={onCancel}>
                    Cancel
                </Button>
                <Button id="submit_button" variant="primary" type="submit">
                    Create user
                </Button>{' '}
            </div>
        </form>
    );
};

export default AddUserForm;
