import * as Yup from 'yup';

// form validation
const relDimension = Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Diese Angabe wird benötigt')
    .integer()
    .min(30, 'Minimum 30%')
    .max(100, 'Maximum 100%');
const absDimension = Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Diese Angabe wird benötigt')
    .integer()
    .min(64, 'Minimum 64px')
    .max(400, 'Maximum 400px');

const ButtonFormValidationSchema = Yup.object().shape({
    width_percent: relDimension,
    height_percent: relDimension,
    width_px: absDimension,
    height_px: absDimension,
    color_main: Yup.string()
        .required('Colorcode wird benötigt')
        .matches(/^#(?:[0-9a-f]{3}){1,2}$/i, 'Ungültiger Colorcode'),
    color_secondary: Yup.string()
        .required('Colorcode wird benötigt')
        .matches(/^#(?:[0-9a-f]{3}){1,2}$/i, 'Ungültiger Colorcode'),
    title: Yup.string().required('Titel wird benötigt').min(5),
    text: Yup.string().required('Text wird benötigt').min(15),
});

export default ButtonFormValidationSchema;
