import api from '../api';

const getAll = async () => {
    return await api.get(`params`);
};

const getAllWidgets = async () => {
    return await api.get(`params/all`);
};

const get = async (id) => {
    return await api.get(`params/${id}`);
};

const create = async (data) => {
    return await api.post(`params`, data);
};

const edit = async (id, data) => {
    return await api.put(`params/${id}`, data);
};

const remove = async (id) => {
    return await api.delete(`params/${id}`);
};

const preview = async (data) => {
    return await api.post(`params/preview`, data);
};

const defaultParameters = {
    color_main: '#0F5319',
    color_secondary: '#006CB8',
    height_percent: 100,
    height_px: 600,
    img_id: 'qpz2czPvD9TbUJrRD4j2NT',
    logo_id: 'fxYmuLwQzNFowd3bi5KYBW',
    text: 'Jede Meldung hilft im Kampf gegen Antisemitismus',
    title: 'Makkabi',
    use_relative_dimensions: true,
    use_custom_header_image: false,
    width_percent: 100,
    width_px: 600,
    referrer_domain: 'https://meine-domain.de',
    formal_addressing: true,
    counter_of_reports: 0,
    use_whistle_icon: true,
    created_at: new Date().toISOString(),
    data_domain: 'https://report-antisemitism.de/privacy/',
    data_domain_label: 'Datenschutz Rias e.V.',
    confirm_window_text:
        'Mit der Nutzung des Meldebuttons für antisemitische Vorfälle im Sport stimmen Sie der Verarbeitung Ihrer Daten durch den Bundesverband RIAS e. V. zu.',
    background_image_confirm_window: true,
};

const WidgetService = {
    getAll,
    getAllWidgets,
    get,
    create,
    edit,
    remove,
    preview,
    defaultParameters,
};

export default WidgetService;
