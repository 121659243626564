import React from 'react';
import { useState, useEffect } from 'react';

import ButtonService from '../../services/button.service';

import LoadingStatus from '../Shared/LoadingStatus';
import DeletingStatus from '../Shared/DeletingStatus';
import SavingStatus from '../Shared/SavingStatus';
import PreviewButton from '../ButtonEdit/PreviewButton';

import { delay } from '../../utils';
import Modal from '../Modal/Modal';
import ButtonPreviewItem from './ButtonPreviewItem';

import styles from './ButtonPreview.module.css';

const ButtonPreview = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState(null);

    const getButtonParameters = async () => {
        try {
            const response = await ButtonService.getAll();
            setData(response.data);
            setLoadError(null);
        } catch (err) {
            setLoadError(err.message);
            setData(null);
        } finally {
            setLoading(false);
            await delay(1000);
            setLoadError(null);
        }
    };

    useEffect(() => {
        getButtonParameters();
    }, []);

    // create new widget from default parameters
    const defaultParams = ButtonService.defaultParameters;
    const [saving, setSaving] = useState(false);
    const [savingText, setSavingText] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveSuccessText, setSaveSuccessText] = useState(null);
    const [saveError, setSaveError] = useState(false);
    const [saveErrorText, setSaveErrorText] = useState(null);

    const createButton = async (data) => {
        try {
            setSaving(true);
            setSavingText(`Erstelle einen neuen Meldebutton...`);
            await delay(1000);
            await ButtonService.create(data);
            setSaveSuccess(true);
            setSaveSuccessText(`Meldebutton erstellt.`);
        } catch (err) {
            setSaveError(err.code);
            setSaveErrorText(`Meldebutton konnte nicht ersellt werden.`);
            await delay(1000);
        } finally {
            setSaving(false);
            getButtonParameters();
            await delay(1000);
            setSaveSuccess(null);
            setSaveError(null);
        }
    };
    // delete widget
    const [deleting, setDeleting] = useState(false);
    const [deletingText, setDeletingText] = useState(null);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteSuccessText, setDeleteSuccessText] = useState(null);
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorText, setDeleteErrorText] = useState(null);

    const deleteButtonById = async (id) => {
        try {
            setDeleting(true);
            setDeletingText(`Lösche Meldebutton ${id} ...`);
            await delay(1001);
            await ButtonService.remove(id);
            setDeleteSuccess(true);
            setDeleteSuccessText(`Meldebutton ${id} erfolgreich gelöscht`);
        } catch (err) {
            setDeleteError(err.message);
            setDeleteErrorText(`Meldebutton ${id} konnte nicht gelöscht werden.`);
            await delay(1000);
        } finally {
            setDeleting(false);
            getButtonParameters();
            await delay(1000);
            setDeleteSuccess(null);
            setDeleteError(null);
        }
    };

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [idToDelete, setIdToDelete] = useState();

    const handleDelete = async () => {
        // delete
        deleteButtonById(idToDelete);

        // close the modal
        setShowDeleteModal(false);
        setIdToDelete();
    };

    const handleDeleteCancel = () => {
        // close the modal
        setShowDeleteModal(false);
        setIdToDelete();
    };

    return (
        <React.Fragment>
            <Modal
                show={showDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleDeleteCancel}
                title="Lösche Meldebutton"
                text="Möchten sie den Meldebutton wirklich löschen?"
                confirmButtonLabel="Löschen"
                cancelButtonLabel="Abbrechen"
            />
            <ul id="button_preview" className={styles.list_grid}>
                {data &&
                    data.map((button) => (
                        <React.Fragment key={button.id}>
                            <ButtonPreviewItem
                                button={button}
                                onDelete={(id) => {
                                    setShowDeleteModal(true);
                                    setIdToDelete(id);
                                }}
                            />
                            <li className={styles['button-preview']}>
                                <PreviewButton params={button} height={button.width_px} width={button.width_px} />
                            </li>
                        </React.Fragment>
                    ))}
                <li>
                    <button
                        onClick={() => {
                            createButton(defaultParams);
                        }}
                    >
                        <b>+</b> neuen Meldebutton anlegen
                    </button>
                </li>
            </ul>
            <LoadingStatus
                loading={loading}
                loadingText="Lade Meldebutton ..."
                error={loadError}
                errorText="Der Meldebutton konnten nicht geladen werden."
            />
            <DeletingStatus
                deleting={deleting}
                deletingText={deletingText}
                error={deleteError}
                errorText={deleteErrorText}
                success={deleteSuccess}
                successText={deleteSuccessText}
            />
            <SavingStatus
                saving={saving}
                savingText={savingText}
                error={saveError}
                errorText={saveErrorText}
                success={saveSuccess}
                successText={saveSuccessText}
            />
        </React.Fragment>
    );
};

export default ButtonPreview;
