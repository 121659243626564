import React, { useEffect, useCallback } from 'react';
import { useBlocker } from 'react-router-dom';
import Modal from '../../Modal/Modal';

/**
 * @param {boolean} isDirty Whether or not the form is dirty
 * @param {() => Promise<void>} onSave Handler for when user chooses to save data
 */
function useFormDirtyStateWarning(isDirty, onSave) {
    // Although this is marked as unstable from react-router, we
    // need this functionality, and it's previous implementation
    // from react-router 5 was removed.
    //
    // Should react-router update with an implementation that is
    // no longer unstable, the package should be updated.
    const blocker = useBlocker(isDirty);

    const eventListener = useCallback((e) => {
        e.preventDefault();
        return (e.returnValue = '');
    });

    // Note that while react-router has its own implementation of
    // beforeunload (useBeforeUnload), as far as I could see it
    // comes with no mechanism to remove the event listener when
    // The component is unmounted, which we need, so that the warning
    // isn't shown again on a different route. Therefore we're
    // using the standard window event listener method
    useEffect(() => {
        if (!isDirty) return;

        window.addEventListener('beforeunload', eventListener);

        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, [isDirty]);

    const saveData = useCallback(() => {
        onSave().then(() => {
            blocker.proceed();
        });
    }, [blocker, onSave]);

    const DirtyStateWarningModal = () => (
        <Modal
            show={blocker.state === 'blocked'}
            title="Sollen Ihre Änderungen gespeichert werden?"
            text="Wenn Sie Ihre Änderungen nicht speichern, gehen sie verloren."
            cancelButtonLabel="Hier bleiben"
            onCancel={blocker.reset}
            confirmButtonLabel="Änderungen nicht speichern"
            onConfirm={blocker.proceed}
            altButtonLabel="Änderungen speichern"
            onAltButtonClick={saveData}
        />
    );

    return {
        DirtyStateWarningModal,
    };
}

export default useFormDirtyStateWarning;
