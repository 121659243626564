import React, { useEffect, useState } from 'react';
import styles from './ColorEdit.module.css';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * Regex to match valid color codes
 *
 * * Leading hash symbol is optional (a user might type in #ffffff or just ffffff)
 * * Case insensitive (a user might type in #ffffff or #FFFFFF)
 * * Matches exactly 3 or exactly 6 (a user might type in #00FF00, or its shorthand #0F0)
 * * Closing group around the hex value, the match result will return the value itself
 *
 * @example
 *
 * '#fff'.match(validColorCode)   // ['#fff', 'fff']
 * '#ffff'.match(validColorCode)  // null
 * 'ffffff'.match(validColorCode) // ['ffffff', 'ffffff']
 */
const validColorCode = /^#?([0-9a-f]{3}|[0-9a-f]{6})$/i;

const ColorEdit = ({ name, ...rest }) => {
    const { setValue, control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                const [manualInputValue, setManualInputValue] = useState(value || '');

                useEffect(() => {
                    if (manualInputValue) return;
                    setManualInputValue(value);
                }, [value]);

                useEffect(() => {
                    if (!manualInputValue) return;

                    const match = manualInputValue.match(validColorCode);

                    if (match == null) return;

                    /** @type {string} */
                    const hexValue = match[1];

                    const isShorthand = hexValue.length === 3;

                    const newValue = isShorthand
                        ? `${hexValue[0]}${hexValue[0]}${hexValue[1]}${hexValue[1]}${hexValue[2]}${hexValue[2]}`
                        : hexValue;

                    const valueToUpdate = `#${newValue}`;

                    setValue(name, valueToUpdate);
                }, [manualInputValue]);

                const handleBlur = () => {
                    const isValid = manualInputValue.match(validColorCode);

                    if (isValid) return;

                    setManualInputValue(value);
                };

                return (
                    <span className={styles['color-container']}>
                        <span className={styles['value-container']} aria-hidden="true">
                            <span>Hex:</span>
                            <input
                                onChange={(event) => {
                                    setManualInputValue(event.target.value);
                                }}
                                onBlur={handleBlur}
                                className={styles['manual-input']}
                                aria-hidden="true"
                                value={manualInputValue}
                            ></input>
                        </span>
                        <input value={value} onChange={onChange} onBlur={onBlur} ref={ref} type="color" {...rest} />
                    </span>
                );
            }}
        />
    );
};

export default ColorEdit;
